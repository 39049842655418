import React from 'react';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import '../../../pages/Home/index.css';

function WhatsAppButton(props) {

  return (
    <div id="fixed-buttom" class="pht-whatsapp z-index-9999">
      <a id="whats-home" class="icon bg-success text-white rounded-circle icon-lg icon-shape shadow zoom"
        href="https://api.whatsapp.com/send?phone=16996500540&text=Olá!%20Vim%20do%20site,%20gostaria%20de%20saber%20mais%20sobre%20o%20PRIT&source=&data="
        target="_blank">
        <WhatsAppIcon></WhatsAppIcon>
      </a>
    </div>
  );
}

export default React.memo(WhatsAppButton);
