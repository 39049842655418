import React from 'react'
import AiImage from '../../../assets/AiRobotPrit.svg';
import { Box, Button } from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography } from '@material-ui/core';

const AiSection = () => {
  const useStyles = makeStyles((theme) => ({
    appIntro: {
      display: 'flex',
      justifyContent: 'center',
      width: '100%',
    },
    title: {
      fontFamily: "Nunito",
      fontStyle: "normal",
      fontWeight: 700,
      fontSize: 40,
      textAlign: 'center',
      marginBottom: theme.spacing(5),
      [theme.breakpoints.down('sm')]: {
        fontSize: 25,
        padding: '0px 10px',
      },
    },
    text: {
      font: 'nunito',
      color: '#7B7F87',
    },
    button: {
      display: 'flex',
      justifyContent: 'start',
      fontFamily: 'nunito',
      width: 'fit-content',
      padding: '0.8rem 1rem',
      marginTop: '1rem',
      backgroundColor: '#39ADC4',
      color: '#FFF',
      fontWeight: 600,
      borderRadius: 16,
      [theme.breakpoints.down('sm')]: {
        justifyContent: 'center',
        margin: '1rem auto 0',
      },
    },
    bold: {
      fontWeight: 800,
    },
    contentContainer: {
      display: 'flex',
      justifyContent: 'space-around',
      alignItems: 'center',
    },
    contentInfo: {
      display: 'flex',
      flexDirection: 'column',
      width: '45%',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        padding: '0 16px',
      },
    },
  }));

  const classes = useStyles();

  return (
    <Box>
      <Grid container className={classes.appIntro}>
        <Typography variant='h4' className={classes.title}> Com a Assistente Virtual no WhatsApp,<br/> seus clientes marcam em segundos! </Typography>
      </Grid>
      <Grid container className={classes.contentContainer}>
        <Box className={classes.contentInfo} >
          <Typography variant="h6" className={classes.text}>
            Agora, além da sua página de serviços, você tem uma <bold className={classes.bold}>nova forma de agendar:</bold> nossa Assistente Virtual no WhatsApp!<br/><br/>
            Com um link exclusivo, <bold className={classes.bold}>seus clientes podem marcar horários direto pelo WhatsApp,</bold> sem complicação. Você escolhe o que divulgar: sua página completa ou o link do WhatsApp para facilitar ainda mais o agendamento.
          </Typography>
          <Button className={classes.button} href='https://www.youtube.com/shorts/twZ3g-dXVmo'>Veja como funciona!</Button>
        </Box>
        <Box> 
          <img src={AiImage} alt='aiRobotImage'/>
        </Box>
      </Grid>
    </Box>
  )
}

export default AiSection;
