import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Container, Grid, Link } from "@material-ui/core";
import PhoneIcon from "../../../assets/icons/icon-whatsapp.png";
import EmailIcon from "../../../assets/icons/icon-email.png";

const useStyles = makeStyles((theme) => ({
  footerContainer: {
    maxWidth: 'none',
    width: "100%",
    margin: 0,
  },
  link: {
    margin: theme.spacing(1, 0),
    color: "#4E5258",
  },
  icon: {
    marginRight: theme.spacing(1),
  },
  hr: {
    margin: theme.spacing(4, 4), 
  },
  title: {
    color: "#39ADC4",
  },
  linkBoxesWrapper: {
    width: '100%',
    display: "flex",
    flexDirection: "row",
    flexWrap: 'wrap',
  },
  centralize: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  linkBoxes: {
    display: "flex",
    flexDirection: "column",
  },
  links: {
    width: 'auto',
    display: "flex",
    flexDirection: "column",
    alignItems: 'flex-start',
    marginLeft: theme.spacing(10),
    [theme.breakpoints.down('md')]: {
      marginLeft: theme.spacing(4),
    },
  },
  spacing: {
    margin: theme.spacing(5, 0),
  },
  body2:{
    fontSize: '0.875rem',
    fontFamily: "Roboto",
    fontWeight: 400,
    lineHeight: 1.43,
    letterSpacing: '0.01071em',
  }
}));

export default function PageFooter() {
  const classes = useStyles();

  return (
    <Container component="footer" className={classes.footerContainer}>
      <hr className={classes.hr} />
      <Grid container className={classes.linkBoxes}>
        <Grid className={classes.linkBoxesWrapper}>
          <Grid container className={classes.links}>
            <Typography variant="h6" className={classes.title}>
              Contato
            </Typography>
            <Link href="https://api.whatsapp.com/send?phone=16996500540&text=Olá!%20Vim%20do%20site,%20gostaria%20de%20saber%20mais%20sobre%20o%20PRIT&source=&data=" className={classes.link}>
              <Grid className={classes.centralize}>
                <img src={PhoneIcon} className={classes.icon} alt="Phone icon"/>
                WhatsApp (16 99650-0540)
              </Grid>
            </Link>
            <Link href="mailto:appprit@gmail.com" className={classes.link}>
              <Grid className={classes.centralize}>
                <img src={EmailIcon} className={classes.icon} alt="Email icon" />
                appprit@gmail.com
              </Grid>
            </Link>
          </Grid>
          <Grid container className={classes.links}>
            <Typography variant="h6" className={classes.title}>
              Prit
            </Typography>
            <Link href="https://www.prit.app/blog/index.html?utm_source=site+prit&utm_medium=botao+no+site+prit" className={classes.link}>
              Blog
            </Link>
            <Link href="https://www.prit.app/politica-de-privacidade.html" title="Política de Privacidade" rel="canonical" className={classes.link}>
              Política de Privacidade
            </Link>
          </Grid>
        </Grid>
        <Grid item xs={12} className={classes.spacing}>
          <Typography variant="body2" color="textSecondary" align="center">
            PRIT GESTÃO DE NEGÓCIOS S.A. - CNPJ: 34.978.956/0002-84
          </Typography>
          <Typography variant="body2" color="textSecondary" align="center">
            Endereço: Avenida Wladimir Meirelles Ferreira, 1660, Sala 1906 -
            Jardim Botânico, Ribeirã<span color="textSecondary" className={classes.body2} onClick={() => {window.open("/profissionais", '_self')}}>o</span> Preto/SP - CEP 14021-630
          </Typography>
          <Typography variant="body2" color="textSecondary" align="center">
            © Prit - Todos os direitos reservados. Endereço eletrônico:
            https://prit.app/
          </Typography>
        </Grid>
      </Grid>
    </Container>
  );
}